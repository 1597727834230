import Head from 'next/head';

import { useSelector } from 'react-redux';
import { selectDisableTracking } from '@store/nativeAppConfig/nativeAppConfig.slice';

const PollsterTrackScript = () => {
  const disableTracking = useSelector(selectDisableTracking);

  if (disableTracking) {
    return null;
  }

  return (
    <Head>
      <script
        id="pollster-track-script"
        type="text/javascript"
        src="//cdn2.pollster.pl/nw.js"
      ></script>
    </Head>
  );
};

export default PollsterTrackScript;
